
import utilsDate from "o365.modules.utils.date.js";

function formatDate(date) {
    if (!date) {
        return null;
    }
    date = new Date(date);
    const curDate = new Date();
    if (curDate.getFullYear() === date.getFullYear()) {
        return utilsDate.format(date, "MMM d");
    } else {
        return utilsDate.format(date, "MMM d yyyy");
    }
}

export default { formatDate }
